'use strict';

import { graphql } from 'react-relay/legacy';

export const sbRespRoot = graphql`
    query sbRespRootQuery(
        $userId: String = ""
        $personalizationId: String = ""
        $guestId: String = ""
        $rerankUserId: String = ""
        $rerankGuestId: String = ""
        $fetchUser: Boolean!
        $uriRef: String = ""
        $originalUrl: String = ""
        $first: Int!
        $page: Int!
        $localeOrigin: String = ""
        $isTrade: Boolean!
        $showSeller: Boolean!
        $followSearchPages: [String]
        $followSearchTypes: [types] = [SEARCH, USER_PREFERENCE]
        $contentModuleId: String = ""
        $previewId: String = ""
        $regions: String = ""
        $userZipCode: String = ""
        $userCountryCode: String = ""
        $fetchRegionalInfo: Boolean = false
        $isClient: Boolean!
        $fetchSb: Boolean!
        $disableForceFacet: Boolean = false
        $disableNonParameterizedUrlRedirects: Boolean = false # Only used for static SB
        $fetchTileVideo: Boolean = false
        # AUCTIONS-1264 - Changing this argument might break carousel UI, make sure the requirements in the ticket description are met.
        $interiorPhotosCount: Int = 10
        $priceBookName: String
        $includeSponsoredItems: Boolean = false
        $regionsList: [String]
        $pageDisplayEnum: PageDisplayEnum = searchAndBrowse
        $engagedItems: [String]
    ) {
        viewer {
            ...SbRespLayout_viewer
            ...detectPageExistsAndRedirect_viewer
        }
    }
`;
